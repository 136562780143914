import { Link } from "react-router-dom";

const NotFound = () => {
  return ( 
    <div className="not-found">
      <h2 className='first-element-spacing main-text' style={{paddingBottom: '1vw'}}>Sorry</h2>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        That page cannot be found.
      </p>
      <Link className='main-text' to="/">Back to the Homepage...</Link>
      <br/>
      {/* This component is present in all pages except Feedback
      <Link to="/Feedback" className="footer"/> */}
    </div>
   );
}
 
export default NotFound;