import { Link } from "react-router-dom";
import data from "./concept_data.json";
import ImageLoader from "./ImageLoader";

const ConceptsAll = ( ) => {

  const imports = data.concepts
  const speakers = []
  for (const i in imports) {
    const speaker = imports[i]
    speakers.push(speaker)
  }

  return ( 
    <div>      
      <p style={{borderBottom: "inset", borderWidth:"6px", height:"3em", width:"94vw", textAlign:"center", padding:"3vw"}}>
        <h3>Deeper dives into the teachings of each of the voices of the Book of Mormon.</h3>
      </p>

      <div className="concept-list-all first-element-spacing">
        {speakers.map((speaker) => (
            <Link key={speaker.id} className="concept-list-all-card" to={ '/cameos/concepts/' + speaker.link }>
              <p style={{width:"15vw"}}><h4> { speaker.name }</h4></p>
            </Link>
        ))}
      </div>
      <br/>
      {/* This component is present in all pages except Feedback
      <Link to="/Feedback" className="footer"/> */}
  </div>
   );
}
 
export default ConceptsAll;