import { Link } from "react-router-dom";

const Intro1 = () => {
  return (  
    <div className="content">
      <h2 className='first-element-spacing main-text' style={{paddingBottom: '1vw'}}>
        What is the Book of Mormon?
      </h2>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        Around A.D. 400, a man named Mormon lived on the American continent. He was part of a civilization known as the Nephites, which was in the process of being wiped out by a civilization known as the Lamanites. Mormon's people, the Nephites, had been keeping religious and historical records since before they arrived in the Americas shortly after 600 B.C.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        Mormon was a prophet of God, like Abraham, Samuel, and Moses in the Old Testament. He was commanded to edit and abridge the records, selecting the parts that were most relevant for God's intended audience. He wrote his compilation on thin gold plates and called it the Book of Mormon.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        The Book of Mormon begins with Mormon's distant ancestor, Lehi. Lehi was a prophet who lived in Jerusalem around the time of the Old Testament prophet Jeremiah (about 600 B.C.). Being a prophet, Lehi did his best to keep the commandments of God. When God commanded him to take his family, friends, and a copy of the Old Testament (as it existed to that point) and leave Jerusalem, he did so. His group eventually sailed across the ocean and landed somewhere on the American coast.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        Lehi's oldest son was named Laman. His fourth son was named Nephi. Nephi was also a prophet. He kept a record of their travels and experiences and did his best to keep the commandments. Laman was not so diligent. As Lehi aged, he deferred more and more to Nephi. Laman felt that Lehi should defer to him because he was the oldest son. Lehi told Laman that he would be Lehi's heir, if he kept the commandments. If he did not, Nephi would become the heir.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        Laman became increasingly angry and resentful against Nephi. Shortly after Lehi's death, for his own safety, Nephi took the part of the group who was diligent in keeping the commandments and left. They traveled far away and settled there. This group was called the people of Nephi, or Nephites. Those who stayed were called the people of Laman, or Lamanites, and they actively hated the Nephites. With one exception, the conflict between the two groups lasted for the next thousand years.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        Mormon was so impressed with Nephi's record-keeping that he included Nephi's record, intact, in his compilation. Then, as now, parents named their children after famous ancestors, so the names Nephi, Lehi, and Laman appear over and over throughout the Book of Mormon.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        Prophets continued to be called and to keep religious records. They foretold of Jesus Christ, the savior of the world, who would atone for their sins. They also talked about his gospel, including faith, repentance, baptism, and the gift of the Holy Ghost. The high point of the Book of Mormon was when Jesus Christ appeared to and taught the people in the Americas, after his death and resurrection in Jerusalem. His appearance was followed by 200 years of peace. 
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        By Mormon's time, around A.D. 400, neither the Nephites nor the Lamanites were keeping the commandments and both were actively fighting the other. Mormon, who fought in the Nephite army, finished his abridgment and then wrote about what was happening in his day. He passed the record to his son, Moroni, before being killed in battle. Moroni recorded the complete destruction of the Nephites, and included an abridgment of the record of an earlier civilization that had also been destroyed. Then, he hid the metal plates in the earth, to await their rediscovery.
      </p>
      <br/><br/><br/><br/>

      {/* This component is present in all pages except Feedback
      <Link to="/Feedback" className="footer"/> */}
    </div>
  );
}
 
export default Intro1;
