import { Link } from "react-router-dom";
import React, { useState } from "react";

const IntroBar = () => {
    const [isVisible, setIsVisible] = useState(true);
    const closeIntro = () => {
        setIsVisible(false);
      };
    return (
        isVisible && (
            <div to='/what-is-BoM' className="new-intro-bar">
                <Link to='/what-is-BoM' className="new-intro-bar-text">New to the Book of Mormon? Click here for an introduction to the text.</Link>
                <span onClick={closeIntro} className="new-intro-bar-close">&times;</span>
            </div>
        )
    )
}

export default IntroBar;