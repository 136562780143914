import { Link } from "react-router-dom";

const Intro2 = () => {
  return (  
    <div className="content">
      <h2 className='first-element-spacing main-text' style={{paddingBottom: '1vw'}}>
      How did the Book of Mormon get here?
      </h2>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
      Mormon's record remained where Moroni had hidden it for a very long time. On the other side of the world, Jesus Christ's apostles had died and people were struggling to continue His church, using the Bible and their own understanding. By the time the 1800s rolled around, there were hundreds of different churches, all based on the Bible and all mostly disagreeing about what things were important and how things ought to be done.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
      When God decided to restore His church, He did as He did in the Old Testament. Rather than trying to correct the existing leadership, He called someone young and inexperienced, who did not have preconceived notions of how things should be done, and would be willing to listen to what God wanted. In the Old Testament, God chose the child Samuel (1 Samuel 3). In the 1800s, God chose 14-year-old Joseph Smith.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
      Joseph Smith was a farmboy who lived in upstate New York. He was relatively uneducated but enjoyed reading the Bible. At that time, a religious revival was going on in the town where he lived, with various ministers encouraging people to join their churches. Joseph truly wanted to join God's church, but he didn't know which one it was. So he decided to ask God about it, as had been recommended in the New Testament (James 1:5).
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
      In the spring of 1820, Joseph went to a grove of trees, where he could be private, and prayed to God. God answered in person, accompanied by His Son, Jesus Christ. Jesus told Joseph that he should not join any of the churches. None of them were His. Joseph became the first prophet in over a millennium.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
      Joseph continued his daily farming routine for the next three years. In 1823, he decided to pray again. God responded, as He did numerous times in both the Old and New Testaments, by sending an angel. An angel named Moroni. The same Moroni who had hidden Mormon's record, now resurrected. Moroni told Joseph about the record and showed him where to find it. 
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
      A few years later, God commanded Joseph to translate and publish Mormon's record. Because Joseph relied on God and not on his intellect and training, he translated the Book of Mormon in about two months. In its current published form, the Book of Mormon is over 500 pages. 
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
      The Book of Mormon contained the gospel of Jesus Christ. God knew that the Bible would not retain its original information as it was copied by hand for over a thousand years before the printing press was invented. Other elements were lost in numerous translations over the years. Mormon's record had been hidden in the earth for centuries and its content was unchanged.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
      At that point, God commanded Joseph to restore His church. On April 6, 1830, Joseph did so. At God's command, he called it the Church of Jesus Christ of Latter-day Saints. The members of God's church in New Testament times were also called saints (Ephesians 2:19) and this name distinguished between the two groups.       
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
      Joseph Smith is no longer with us, but the church he organized still is and it still has a prophet. To find out more about the Church of Jesus Christ of Latter-day Saints, visit <a className="link" href="https://www.comeuntochrist.org">ComeUntoChrist.org</a>.
      </p>
      <br/><br/><br/><br/>

      {/* This component is present in all pages except Feedback
      <Link to="/Feedback" className="footer"/> */}
    </div>
  );
}
 
export default Intro2;
