import { Link } from "react-router-dom";
//import stylo_xr_thumbnail from './images/thumbnails/stylo_xr.png'
import timeline_thumbnail from './images/thumbnails/timeline.png'
import connections_thumbnail from './images/thumbnails/connections.png'
import network_diagram_thumbnail from './images/thumbnails/speakers.png'
import word_bubbles_thumbnail from './images/thumbnails/word_bubbles.png'
import bom_semanticmap_thumbnail from './images/thumbnails/bomsemanticmap.png'
import bible_semanticmap_thumbnail from './images/thumbnails/biblesemanticmap.png'
import similar_verse_thumbnail from './images/thumbnails/similarverse.png'
import social_network_thumbnail from './images/thumbnails/social_network.png'

import placeholder from './images/SC-Book-Of-Mormon-Central-Favicon-Black-32px.png'

const Toolbox = () => {
  return ( 
    <div className='first-element-spacing main-text'>
      <h2 style={{paddingBottom: '1vw'}}>Toolbox</h2>
      <div className="content-toolbox">
        <Link className='toolbox-container' to={ '/toolbox/timeline' }>
          <div className="toolbox-item">
            <h4 className='toolbox-item-label'>Timeline</h4>
            <img src={ timeline_thumbnail } alt="" />
          </div>
        </Link>
        <Link className='toolbox-container' to={ '/toolbox/word-bubbles' }>
          <div className="toolbox-item">
            <h4 className='toolbox-item-label'>Word Bubbles</h4>
            <img src={ word_bubbles_thumbnail } alt="" />
          </div>
        </Link>
        <Link className='toolbox-container' to={ '/toolbox/connections' }>
          <div className="toolbox-item">
            <h4 className='toolbox-item-label'>Connections</h4>
            <img src={ connections_thumbnail } alt="" />
          </div>
        </Link>
        <Link className='toolbox-container' to={ '/toolbox/network-diagram' }>
          <div className="toolbox-item">
            <h4 className='toolbox-item-label'>Network Diagram</h4>
            <img src={ network_diagram_thumbnail } alt="" />
          </div>
        </Link>

        <Link className='toolbox-container' to={ '/toolbox/BOM-semantic-map' }>
          <div className="toolbox-item">
            <h4 className='toolbox-item-label'>Book of Mormon Semantic Map</h4>
            <img src={ bom_semanticmap_thumbnail } alt=""/>
          </div>
        </Link>
        <Link className='toolbox-container' style={{display:'none'}}to={ '/toolbox/Bible-semantic-map' }>
          <div className="toolbox-item">
            <h4 className='toolbox-item-label'>Holy Bible Semantic Map</h4>
            <img src={ bible_semanticmap_thumbnail } alt=""/>
          </div>
        </Link>
        <Link className='toolbox-container' to={ '/toolbox/similar-verse' }>
          <div className="toolbox-item">
            <h4 className='toolbox-item-label'>Similar Verse Finder</h4>
            <img src={ similar_verse_thumbnail } alt=""/>
          </div>
        </Link>
        <Link className='toolbox-container' to={ '/toolbox/social-network' }>
          <div className="toolbox-item">
            <h4 className='toolbox-item-label'>Social Network</h4>
            <img src={ social_network_thumbnail  } alt=""/>
          </div>
        </Link> 
      </div>
      <br/>
      {/* This component is present in all pages except Feedback
      <Link to="/Feedback" className="footer"/> */}
    </div>
   );
}
 
export default Toolbox;

/*
        <Link className='toolbox-container' to={ '/toolbox/stylo-xr' }>
          <div className="toolbox-item">
            <h4 className='toolbox-item-label'>Book of Mormon Explorer VR</h4>
            <img src={ stylo_xr_thumbnail } alt="" />
          </div>
        </Link>
*/
