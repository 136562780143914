import { Link } from "react-router-dom";
import data from "./people_data.json";
import ImageLoader from "./ImageLoader";

const PeopleAll = ( ) => {

  const imports = data.people
  const speakers = []
  for (const i in imports) {
    const speaker = imports[i]
    if (speaker.img != "") speakers.push(speaker)
  }

  return ( 
    <div className="speaker-list-all first-element-spacing" style={{textAlign: 'center'}}>
      
      {speakers.map((speaker) => (
          <Link key={speaker.id} className="speaker-list-all-card" to={ '/cameos/people/' + speaker.link }>
            <img className="speakerall-img" src={ImageLoader(speaker.img)} alt="" />
            <h4>{ speaker.name }</h4>
          </Link>
      ))}
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      {/* This component is present in all pages except Feedback
      <Link to="/Feedback" className="footer"/> */}
    </div>
  );
}
 
export default PeopleAll;