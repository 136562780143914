import { Link } from "react-router-dom";

const Tutorial = () => {
  return (  
    <div className="content">
      <h2 className='first-element-spacing main-text' style={{paddingBottom: '1vw'}}>
        This page is a placeholder.
      </h2>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        One day, we'll put a cool tutorial here for people who are familiar with the Book of Mormon, but not the Explorer website.
      </p>
      
      <br/><br/><br/><br/>

      {/* This component is present in all pages except Feedback
      <Link to="/Feedback" className="footer"/> */}
    </div>
  );
}
 
export default Tutorial;
